import invoiceList from "./invoicelist";

const generateInvoiceNo = async() => {

    const listOfInvoice = await invoiceList();
    let invoiceFormate;
    if(Array.isArray(listOfInvoice.data) && listOfInvoice.data.length === 0) {
        invoiceFormate = 'DJ' + "-" + "01";
    } else {
        const inNumber = listOfInvoice.data[listOfInvoice.data.length -1].invoiceNo;
        const no = inNumber.toString().split('-');
        const gnNo = Number(no[no.length -1]) + 1;
        
        if(String(gnNo).length === 1) {
            invoiceFormate = 'DJ' + "-" + "0" + String(gnNo);
        } else {
            invoiceFormate = 'DJ' + "-" + String(gnNo);
        }
    }
    return invoiceFormate;
}

export default generateInvoiceNo;
