import auth from "./auth";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const invoiceUrl = process.env.REACT_APP_INVOICE;
const invoiceDemoUrl = process.env.REACT_APP_DEMO_EDIT;

const editInvoiceDetailsById = async(data) => {

    const authToken = auth();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
        }
    };

    try {
        const result = await axios.put(`${baseUrl}${invoiceDemoUrl}`, JSON.stringify(data), config);
        return result.data;

    } catch(err) {
        console.log('error', err);
    }
}

export default editInvoiceDetailsById;
