import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Pagination from "rc-pagination";
import cloneDeep from "lodash/cloneDeep";
import "rc-pagination/assets/index.css";
import invoiceList from "../utils/invoicelist";
import edit from "../images/edit.png";
import dlt from "../images/dlt.png";
import download from "../images/download.png";
import deleteInvoice from "../utils/deleteinvoice";
import Spinner from "../component/loader";

const tableHead = {
    sn: 'S.N',
    invoiceNo: "Invoice No",
    date: "Date",
    customerName: "Customer Name",
    phoneNo: 'Phone No.',
    bullion: "Bullion",
    invoiceAmount: "Invoice Amount",
    edit: "Edit",
    delete: "Delete",
    download: "Download"
};


const Table = () => {
    const navigate = useNavigate();
    const countPerPage = 10;
    const [value, setValue] = useState("");
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteData, setDeleteData] = useState('');
    const [loading, setLoading] = useState(false)

    // const [collection, setCollection] = useState(
    //     cloneDeep(data.slice(0, countPerPage))
    // );

    // const searchData = useRef(
    //     throttle(val => {
    //         const query = val.toLowerCase();
    //         setCurrentPage(1);
    //         const data = cloneDeep(
    //             data
    //                 .filter(item => item.name.toLowerCase().indexOf(query) > -1)
    //                 .slice(0, countPerPage)
    //         );
    //         setData(data);
    //     }, 400)
    // );

    useEffect(() => {
        (async () => {
            const result = await invoiceList();
            setData(result.data);
            setLoading(true);
        })();
    }, [deleteData]);

    const updatePage = p => {
        setCurrentPage(p);
        const to = countPerPage * p;
        const from = to - countPerPage;
        // setCollection(cloneDeep(collection.slice(from, to)));
        setData(cloneDeep(data.slice(from, to)));
    };

    const redirectToEditPage = (invoiceId, itemId) => {
        navigate(`/invoice/${invoiceId}/${itemId}`);
    }


    const headRow = () => {
        return Object.values(tableHead).map((title, index) => (
            <td key={index}>{title}</td>
        ));
    };

    const downLoadPdf = (invoiceNo) => {
        navigate(`/saleinvoice/${invoiceNo}`);
    }

    const deleteRow = async (index) => {
        console.log('index', index);

        setDeleteData(index);
        const result = await deleteInvoice(index);
        setData(result.data);
    }


    return (
        <>
            {
                !loading ? <Spinner /> : <>

                    <table style={{ width: "100%"}}>
                        <thead>
                            <th className="table-header" colSpan={10}><h6>Invoice Summary</h6></th>
                            {/* <tr>
                                <td className="search" colSpan={10}>
                                    <input
                                        placeholder="Search..."
                                        value={value}
                                        onChange={e => setValue(e.target.value)}
                                    />
                                </td>
                            </tr> */}
                            <tr>
                                <td className="middle-invoice" colSpan={10}>Invoice Summary</td>
                            </tr>
                            <tr colSpan={9}>{headRow()}</tr>
                        </thead>
                        {
                            (Array.isArray(data) && data?.length > 0) ? <>
                                {
                                    data.map((val, key) => {
                                        {
                                            if (Array.isArray(val.items) && val?.items?.length === 1) {
                                                let itemCost = 0;
                                                let itemNetWeight;

                                                if (val.items[0].bullion === "Gold(10gm)") {
                                                    itemCost = val.items[0].itemPrice * Number(val.items[0].netWeight.split('g')[0]) / 10;
                                                }

                                                if (val.items[0].bullion === "Silver(1kg)") {
                                                    itemCost = val.items[0].itemPrice * Number(val.items[0].netWeight.split('g')[0]) / 1000;
                                                }
                                                if (val.items[0].bullion === "NPM" || val.items[0].bullion === "Silver925") {
                                                    itemCost = val.items[0].itemPrice;
                                                }

                                                if (val.items[0].stoneWeight) {
                                                    itemNetWeight = Number(val.items[0].stoneWeight.split('g')[0]) * val.items[0].stoneRate;
                                                }
                                                if (itemNetWeight || itemCost) {
                                                    itemCost = Number(itemCost ? itemCost : 0) + Number(itemNetWeight ? itemNetWeight : 0);
                                                }

                                                return (
                                                    <tr key={val._id}>
                                                        <td>{key + 1}</td>
                                                        <td>{val.invoiceNo}</td>
                                                        <td>{val.createdAt}</td>
                                                        <td>{val?.userId?.name}</td>
                                                        <td>{val?.userId?.mobileNo}</td>
                                                        <td>{val?.items[0]['bullion']}</td>
                                                        <td>{val?.items[0]['totalCost'] ? val?.items[0]['totalCost'] : itemCost}</td>
                                                        <td>
                                                                    <button className="image-size button-border">
                                                                        <img src={edit} alt="Edit" onClick={() => redirectToEditPage(val.invoiceNo, data._id)} />
                                                                    </button>
                                                                </td>
                                                        <td id={val.invoiceNo}>
                                                            <button className="image-size button-border" onClick={() => deleteRow(val.invoiceNo)}>
                                                                <img src={dlt} alt="Delete" />
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button className="image-size button-border" onClick={() => downLoadPdf(val.invoiceNo)}>
                                                                <img src={download} alt="Download" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                return (
                                                    val?.items?.map((data, k) => {

                                                        let itemCost = 0;
                                                        let itemNetWeight;

                                                        if (data.bullion === "Gold(10gm)") {
                                                            itemCost = data.itemPrice * Number(data.netWeight.split('g')[0]) / 10;
                                                        }
                                                        if (data.bullion === "Silver(1kg)") {
                                                            itemCost = data.itemPrice * Number(data.netWeight.split('g')[0]) / 1000;
                                                        }
                                                        if (data.bullion === "NPM" || data.bullion === "Silver925") {
                                                            itemCost = data.itemPrice;
                                                        }
                                                        if (data.stoneWeight) {
                                                            itemNetWeight = Number(data.stoneWeight.split('g')[0]) * data.stoneRate;
                                                        }
                                                        if (itemNetWeight || itemCost) {
                                                            itemCost = Number(itemCost ? itemCost : 0) + Number(itemNetWeight ? itemNetWeight : 0);
                                                        }
                                                        const indx = key + k + 1;
                                                        return (
                                                            <tr key={data._id}>
                                                                <td>{indx}</td>
                                                                <td>{val.invoiceNo}</td>
                                                                <td>{val.createdAt}</td>
                                                                <td>{val?.userId?.name}</td>
                                                                <td>{val?.userId?.mobileNo}</td>
                                                                <td>{data.bullion}</td>
                                                                <td>{data.totalCost ? data.totalCost : '-'}</td>
                                                                <td>
                                                                    <button className="image-size button-border">
                                                                        <img src={edit} alt="Edit" onClick={() => redirectToEditPage(val.invoiceNo, data._id)} />
                                                                    </button>
                                                                </td>
                                                                <td id={val.invoiceNo}>
                                                                    <button className="image-size button-border" onClick={() => deleteRow(val.invoiceNo)}>
                                                                        <img src={dlt} alt="Delete" />
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button className="image-size button-border" onClick={() => downLoadPdf(val.invoiceNo)}>
                                                                        <img src={download} alt="Download" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                )
                                            }
                                        }
                                    })
                                }
                            </> : <></>
                        }
                    </table>
                    {/* <Pagination
                        pageSize={countPerPage}
                        onChange={updatePage}
                        current={currentPage}
                        total={data?.length}
                    /> */}
                </>
            }
        </>
    );
};

export default Table;
