import React, { useState, useEffect } from 'react';
import convertToEnglish from '../../utils/convertToEnglish';

const Notes = ({ amount, discount, cgst, sgst, notes, paymentMethod }) => {
    let [cgstValue, setCgstValue] = useState(0);
    let [sgstValue, setSgstValue] = useState(0);
    let [totalvalue, setTotalValue] = useState(0);
    let [totalValueWithGst] = useState(0);
    let [totalValueWithDiscount] = useState(0);
    const [payableAmount, setPayableAmount] = useState(0);

    if (cgst || sgst) {
        cgstValue = (amount * (1.5 / 100)).toFixed(2);
        sgstValue = cgstValue;
        totalValueWithGst = (amount + Number(cgstValue) + Number(sgstValue)).toFixed(2);
        totalvalue = totalValueWithGst;
    }

    if (discount) {
        totalValueWithDiscount = (totalvalue ? totalvalue : totalvalue) - discount;
        totalvalue = totalValueWithDiscount;
    }

    let numberWithComma = Intl.NumberFormat('en-IN');
    numberWithComma = numberWithComma.format(totalvalue ? totalvalue : amount);

    useEffect(() => {
        setTotalValue(amount);
        setCgstValue(cgst);
        setSgstValue(sgst);
        const textFormat = convertToEnglish(totalvalue ? totalvalue : amount);
        setPayableAmount(textFormat);
    }, [totalvalue, amount]);


    return (
        <>
            <div className='notes-details'>
                <p className='lg:w-1/2 text-justify text-font-size'><b>Notes:</b>&ensp;{notes ? notes : '-'}</p>
                <table className='amount-details text-font-size tr-amount-border'>

                    <tr className='tr-amount-border'>
                        <td><span><b>AMOUNT:</b></span>&ensp; {amount ? amount : '-'}</td>
                    </tr>
                    {cgst ? <>
                        <tr className='tr-amount-border'>
                            <td><span>CGST(1.5):</span>&ensp; {cgst ? cgstValue : 0}</td>
                        </tr>
                        <tr className='tr-amount-border'>
                            <td><span>SGST(1.5):</span>&ensp; {sgst ? sgstValue : 0}</td>
                        </tr>
                    </> : <>

                    </>}

                    <tr className='tr-amount-border'>
                        <td><span>Discount:</span>&ensp; {discount ? discount : '-'}</td>
                    </tr>
                    <tr className='tr-amount-border'>
                        <td>{`(${paymentMethod})`}&ensp; <span><b>TOTAL AMOUNT: &ensp;<i className="fa-solid fa-indian-rupee-sign"></i></b></span> {numberWithComma ? numberWithComma : amount}</td>
                    </tr>
                </table>
            </div>
            <p className='payment-bill'>
                <span><b>PAYABLE AMOUNT :</b></span>&emsp; {payableAmount ? payableAmount : '-'}
            </p>
        </>
    )
}

export default Notes;
