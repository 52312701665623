import auth from "./auth";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const invoiceByIdUrl = process.env.REACT_APP_INVOICE;

const invoiceDetailsById = async(id) => {
    const authToken = auth();
    const config = {
        headers: {
            Authorization: authToken
        }
    };

    try {
        const result = await axios.get(`${baseUrl}${invoiceByIdUrl}/${id}`, config);
        return result.data;

    } catch(err) {
        console.log('error', err);
    }
}

export default invoiceDetailsById;
