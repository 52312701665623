import auth from "./auth";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const invoiceListUrl = process.env.REACT_APP_INVOICE_LIST;

const invoiceList = async() => {
    const authToken = auth();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authToken
        }
    };

    try {
        const result = await axios.get(`${baseUrl}${invoiceListUrl}`, config);
        return result.data;

    } catch(err) {
        console.log('error', err);

    }
}

export default invoiceList;