import React, { useState, useRef, useEffect } from "react";
import ReactToPrint from 'react-to-print';
import { useParams } from "react-router-dom";
import Footer from "./footer";
import Table from "./table";
import invoiceDetailsById from "../../utils/invoiceDetailsById";
import ClientDeatils from "./clientsDetails";

const SalesInvoice = () => {

    const [invoiceData, setInvoiceData] = useState([]);
    const params = useParams();
    const componentRef = useRef();


    useEffect(() => {
        (async () => {
            const result = await invoiceDetailsById(params.id);
            if(result.data !== null) {
                setInvoiceData(result.data);
            }
        })();
    }, []);

    
    return (
        <>
            <main className="m-5 p-5 rounded shadow bg-white xl:max-w-4xl md:max-w-xl md:mx-auto lg:max-w-2xl xl:mx-auto">
                <ReactToPrint trigger={() => <button className="bg-blue-500 text-white font-bold py-2 px-8 rounded shadow brder-2 border-bule-500 
                        hover:text-blue-500 transition-all duration-300">Print/Download</button>}
                    content={() => componentRef.current}
                />

                <div ref={componentRef} className="invoice-table-body">

                    {/* Client details */}
                    <ClientDeatils invoiceNo={invoiceData.invoiceNo} oDate={invoiceData.orderDate} dDate={invoiceData.deliveryDate} clientDetails={invoiceData.userId} />

                    {/* Table */}
                    <Table tableData={invoiceData.items} discount={invoiceData.discount ? invoiceData.discount : ''} cgst={invoiceData.cgst} sgst={invoiceData.sgst} notes={invoiceData.notes} paymentMode={invoiceData.paymentMode}/>

                    {/* CALLED FOOTER COMPONENT */}
                    <Footer />
                </div>
            </main>
        </>
    )
}

export default SalesInvoice;
