import React, { useState } from 'react'
import bullionArrow from "../../images/bullionarrow.png";
import Notes from './notes';

const Table = ({ tableData, discount, cgst, sgst, notes, paymentMode }) => {

  // let [stoneCost] = useState(0);
  // let [totalCost] = useState(0);
  let [totalItemCost] = useState(0);
  // let [labour] = useState(0);

  return (
    <>
      <h2 className='text-color'><b>Invoice</b></h2>

      <div className='div1 bullion-arrow-text'>
        <p></p>
        <img src={bullionArrow} alt='Right Arrow'/>
        <p>Bullion Detail</p>
        <img src={bullionArrow} alt='Left Arrow'/>
        <p></p>
        <img src={bullionArrow} alt='Right Arrow'/>
        <p>Stone Detail</p>
        <img src={bullionArrow} alt='Left Arrow'/>
      </div >

      <table className='invoice-table'>
        <thead>
          <tr className='p-1 invoice-table-header'>
            <td className='invoice-right-border'>Item Name</td>
            <td className='invoice-right-border'>Bullion</td>
            <td className='invoice-right-border'>Rate</td>
            <td className='invoice-right-border'>Gross<br />Weight</td>
            <td className='invoice-right-border'>Net<br />Weight</td>
            <td className='right-border'>Item Cost</td>
            <td className='invoice-right-border'>Decscription</td>
            <td className='invoice-right-border'>Stone<br />Weight</td>
            <td className='invoice-right-border'>Pcs.</td>
            <td className='invoice-right-border'>Stone<br />Rate</td>
            <td className='right-border'>Stone<br />Cost</td>
            <td className='right-border'>Labour</td>
            <td className='invoice-right-border'>Total Cost</td>
          </tr>
        </thead>
        <tbody>
          {tableData?.map((key, index) => {
            // let itemCost = 0;
            // stoneCost = 0;

            // if (key.bullion === "Gold(10gm)") {
            //   itemCost = key.itemPrice * Number(key.netWeight.split('g')[0]) / 10;
            // }
            // if (key.bullion === "Silver(1kg)") {
            //   itemCost = key.itemPrice * Number(key.netWeight.split('g')[0]) / 1000;
            // }
            // if (key.bullion === "NPM" || key.bullion === "Silver925") {
            //   itemCost = key.itemPrice;
            // }
            // if(key.stoneWeight) {
            //   stoneCost = Number(key.stoneWeight.split('g')[0]) * key.stoneRate;
            // }
            // if(key.labour) {
            //   labour = key.labour ? key.labour : 0;
            // }

            // totalCost = itemCost + stoneCost + labour;
            totalItemCost = totalItemCost + key.totalCost;

            // labour = 0;
            return (
              <tr key={index}>
                <td className='invoice-right-border'>{key.itemName ? key.itemName : '-'}</td>
                <td className='invoice-right-border'>{key.bullion ? key.bullion : '-'}</td>
                <td className='invoice-right-border'>{key.itemPrice ? key.itemPrice : '-'}</td>
                <td className='invoice-right-border'>{(key.groceWeight == 'g' || key.groceWeight == '0g') ? '-' : key.groceWeight}</td>
                <td className='invoice-right-border'>{(key.netWeight == 'g' || key.netWeight == '0g') ? '-' : key.netWeight}</td>
                <td className='right-border'>{key.itemCost ? key.itemCost : '-'}</td>
                <td className='invoice-right-border'>{key.description ? key.description : '-'}</td>
                <td className='invoice-right-border'>{key.stoneWeight ? key.stoneWeight : '-'}</td>
                <td className='invoice-right-border'>{key.stonePcs ? key.stonePcs : '-'}</td>
                <td className='invoice-right-border'>{key.stoneRate ? key.stoneRate : '-'}</td>
                <td className='right-border'>{key.stoneCost ? key.stoneCost : '-'}</td>
                <td className='right-border'>{key.labour ? key.labour : '-'}</td>
                <td className='invoice-right-border'>{key.totalCost ? key.totalCost : '-'}</td>
              </tr>
            )
          })}

        </tbody>
      </table>

      <Notes amount={totalItemCost} discount={discount} cgst={cgst} sgst={sgst} notes={notes} paymentMethod={paymentMode}/>

    </>
  )
}

export default Table;
