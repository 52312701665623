import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import ModalPopup from '../component/modal';
import plus from '../images/plus.png';
import Delete from '../images/delete.png';
import Select from "react-select";
import editInvoiceDetailsById from '../utils/editInvoiceById';
import invoiceDetailsById from "../utils/invoiceDetailsById";
import createInvoice from '../utils/createInvoice';
import userListByName from '../utils/userDetailsByName';
import { ShowErrorToast } from '../component/error/Toast';
import generateInvoiceNo from '../utils/generateInvoiceNo';
import { deleteInvoiceByItemId } from '../utils/deleteinvoice';
import Spinner from '../component/loader';

const Invoice = () => {

    const params = useParams();
    const navigate = useNavigate();

    const invoiceId = params.invoiceId;
    const itemId = params.itemId;
    let name, value;

    const [invoiceNo, setInvoiceNo] = useState(0);
    const [allchecked, setAllChecked] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [status, setStatus] = useState('');

    const [discount, setDiscount] = useState(false);
    let [amount] = useState(0);
    const [discountValue, setDiscountValue] = useState("");
    const [selectedName, setSelectedName] = useState();
    const [editData, setEditData] = useState([]);
    const [loading, setLoading] = useState(false);
    // let [isHide, setIsHide] = useState(false);
    let [availableUserName] = useState([]);
    let [itemCostPerItem] = useState(0);
    let [totalItemCostPerItem] = useState(0);
    let [stoneCostPerItem] = useState(0);

    // ornaments detail...
    const [Ornaments, setOrnaments] = useState({
        userId: "",
        orderDate: "",
        deliveryDate: "",
        notes: ""
    });

    const [rows, initRow] = useState([{
        rowId: "",
        itemName: "",
        bullion: "",
        itemPrice: "",
        groceWeight: "",
        netWeight: "",
        itemCost: "",
        description: "",
        stoneWeight: "",
        stonePcs: "",
        stoneRate: "",
        stoneCost: "",
        totalCost: "",
        labour: ""
    }]);

    useEffect(() => {
        const getUserList = async () => {

            try {
                const searchName = selectedName ? selectedName : '';
                const result = await userListByName(searchName);
                setUserNameInOptionList(result.data);

                if (invoiceId) {

                    await editPreFieldData();
                    await preFieldData();
                    setLoading(true);
                }

            } catch (err) {
                console.log(err, 'error');
                return err;
            }
        }

        getUserList();
        invoiceValue();
    }, [!loading, status]);

    const editPreFieldData = async () => {

        try {
            if (invoiceId) {
                const invoiceDetails = await invoiceDetailsById(invoiceId);
                setEditData(invoiceDetails.data);
            }

        } catch (err) {
            console.log(err, 'error');
        }
    }

    const formatDate = (d) => {
        let date = new Date(d)
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) { dd = '0' + dd }
        if (mm < 10) { mm = '0' + mm };
        return d = yyyy + '-' + mm + '-' + dd;
    }

    const preFieldData = async () => {

        if (editData && typeof editData === 'object' && editData.constructor === Object) {
            setSelectedName({ value: editData.userId._id, label: editData.userId.name });
            setInvoiceNo(editData.invoiceNo);
            setSelectedOption(editData.paymentMode);
            if (editData.cgst !== "") {
                setIsChecked(editData.cgst);
            }
            if (editData.discount) {
                setDiscount(editData.discount);
                setDiscountValue(editData.discount)
            }

            const dDate = formatDate(editData.deliveryDate);
            const oDate = formatDate(editData.orderDate);

            setOrnaments({
                deliveryDate: dDate,
                orderDate: oDate,
                notes: editData.notes
            })

            let rowArray = [];
            editData.items.map(key => {
                const rowData = {
                    rowId: key._id,
                    itemName: key.itemName ? key.itemName : '',
                    bullion: key.bullion ? key.bullion : '',
                    itemPrice: key.itemPrice ? key.itemPrice : '',
                    labour: key.labour ? key.labour : '',
                    groceWeight: key.groceWeight ? (Number(key.groceWeight.toString().split('g')[0])) : '',
                    netWeight: key.netWeight ? (Number(key.netWeight.toString().split('g')[0])) : '',
                    itemCost: key.itemCost ? key.itemCost : '',
                    description: key.description ? key.description : '',
                    stoneWeight: key.stoneWeight ? ((Number(key.stoneWeight.toString().split('g')[0]))) : '',
                    stonePcs: key.stonePcs ? key.stonePcs : '',
                    stoneRate: key.stoneRate ? key.stoneRate : '',
                    stoneCost: key.stoneCost ? key.stoneCost : '',
                    totalCost: key.totalCost ? key.totalCost : ''
                }
                return rowArray.push(rowData);
            });

            initRow([...rowArray])
            // setLoading(false);
        }

    }

    const setUserNameInOptionList = (data) => {
        if (Array.isArray(data) && data.length > 0) {
            data.map(key => {
                if(key.userType !== "Admin") {
                    return availableUserName.push({ value: key._id, label: key.name });
                }
            })
            if(Array.isArray(availableUserName) && availableUserName.length === 0) {
                return ShowErrorToast("Please create a user!")
            }
        }
    };

    const handleInput = (e) => {
        name = e.target.name;
        value = e.target.value;
        setOrnaments({ ...Ornaments, [name]: value })
    }

    const addRowTable = () => {
        const data = {
            rowId: "",
            itemName: "",
            bullion: "",
            itemPrice: "",
            groceWeight: "",
            netWeight: "",
            itemCost: "",
            description: "",
            stoneWeight: "",
            stonePcs: "",
            stoneRate: "",
            stoneCost: "",
            totalCost: "",
            labour: ""
        };

        initRow([...rows, data]);

    };

    // const deleteRow = async (invoiceId, data) => {
    //     const result = await deleteInvoiceByItemId(invoiceId, data[0].rowId);
    //     if (result.status === 200) {
    //         Swal.fire({
    //             title: result.data.message,
    //             confirmButtonText: 'Ok'
    //         })
    //     }

    // }

    // remove table
    const tableRowRemove = async (index) => {

        const dataRow = [...rows];
        dataRow.splice(index, 1);
        initRow(dataRow);
        // return

        // from api data will be delete
        // deletedRow.map(async key => {
        //     if ((key.itemName !== "" && key.bullion !== "")) {
        //         console.log('kkkkk');
        //         await deleteRow(invoiceId, deletedRow);
        //     }
        // })


    }

    const onValUpdate = (i, event) => {
        const { name, value } = event.target;
        const data = [...rows];
        if (name === "bullion" && (value === "Silver(1kg)" || value === "Gold(10gm)")) {
            // setIsHide(false)
        }
        if (name === "bullion" && (value === "NPM" || value === "Silver925")) {
            // setIsHide(true)
        }
        if (name === "groceWeight" || name === "netWeight" || name === "stoneWeight") {
            data[i][name] = value
        }

        data[i][name] = value;
        initRow(data);
    };

    const invoiceValue = async () => {

        const invoiceNoD = await generateInvoiceNo();
        setInvoiceNo(invoiceNoD);
    }

    const printDownload = async (invoiceNo) => {
        navigate(`/saleinvoice/${invoiceNo}`);
    }

    const onSave = async (print) => {
        if (!(selectedName)) {
            return ShowErrorToast('Please add user name!')
        }

        const checkOrDate = new Date(Ornaments.orderDate);
        const checkDelvDate = new Date(Ornaments.deliveryDate);

        if (checkOrDate <= checkDelvDate) {
            //
        } else {
            return ShowErrorToast('Please select order delivery date after a day atleast!');
        }

        const data = {
            userId: selectedName.value,
            invoiceNo: invoiceNo,
            orderDate: Ornaments.orderDate,
            deliveryDate: Ornaments.deliveryDate,
            notes: Ornaments.notes,
            paymentMode: selectedOption,
            sgst: isChecked === true ? 1.5 : 0,
            cgst: isChecked === true ? 1.5 : 0,
            discount: discount === true ? discountValue : 0,
            items: rows
        }

        if (!data.invoiceNo) {
            return ShowErrorToast('Please add invoice number!');
        }
        if (!data.orderDate) {
            return ShowErrorToast('Please add OrderDate!');
        }
        if (!data.deliveryDate) {
            return ShowErrorToast('Please add DeliveryDate!')
        }

        data.items.map(key => {
            if ((key.itemName === "" && key.stoneWeight === "")) {
                return ShowErrorToast(`It seems you have not select Item Name or Stone Weight, Please select any of them to purchase item!`)
            }
            if (key.itemName !== "") {
                if ((key.bullion === "")) {
                    return ShowErrorToast(`bullion can not be empty!`)
                }
                if (key.labour === "") {
                    key.labour = "00";
                }
                if ((key.itemPrice === "")) {
                    return ShowErrorToast(`itemPrice can not be empty!`)
                }
                if (key.bullion === "NPM" || key.bullion === "Silver925") {
                    // 
                } else {
                    if ((key.groceWeight === "")) {
                        return ShowErrorToast(`groceWeight can not be empty!`)
                    }
                    if ((key.netWeight === "")) {
                        return ShowErrorToast(`netWeight can not be empty!`)
                    }
                }
                // if (key.bullion !== "Silver925") {
                //     if ((key.groceWeight === "")) {
                //         return ShowErrorToast(`groceWeight can not be empty!`)
                //     }
                //     if ((key.netWeight === "")) {
                //         return ShowErrorToast(`netWeight can not be empty!`)
                //     }
                // }
            }
            if (key.stoneWeight !== "") {
                if (key.stoneRate === "") {
                    return ShowErrorToast(`stoneRate can not be empty!`)
                }
            }
        })

        if (!data.paymentMode) {
            return ShowErrorToast(`Please select a payment method!`)
        }

        // setLoading(true);
        data.invoiceId = invoiceNo;
        
        try {
            if (invoiceId && itemId) {
                const editInvoiceDetails = await editInvoiceDetailsById(data);
                if (editInvoiceDetails.status === 200) {
                    Swal.fire({
                        title: 'Invoice data updated!',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // setLoading(false);
                            if (print === "print") {
                                printDownload(invoiceNo)
                            } else {
                                navigate("/report");
                            }
                            availableUserName = [];
                            initRow([{
                                rowId: "",
                                itemName: "",
                                bullion: "",
                                itemPrice: "",
                                groceWeight: "",
                                netWeight: "",
                                itemCost: "",
                                description: "",
                                stoneWeight: "",
                                stonePcs: "",
                                stoneRate: "",
                                stoneCost: "",
                                totalCost: ""
                            }])

                            setOrnaments({
                                userId: "",
                                orderDate: "",
                                deliveryDate: "",
                                notes: ""
                            })
                        }
                    })
                }
            } else {
                const invoiceCreate = await createInvoice(data);
                if (print === "print" && invoiceCreate.status === 200) {
                    Swal.fire({
                        title: 'Invoice created!',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setLoading(false);
                            if (print === "print") {
                                printDownload(invoiceCreate.data.invoiceNo)
                            } else {
                                navigate("/report");
                            }
                            availableUserName = [];
                        }
                    })
                }
            }
        } catch (err) {
            console.log(err, 'error');

        }
    }

    // handle radiobutton
    function onValueChange(event) {
        setSelectedOption(event.target.value);
    }

    // handle checkbox
    function handleChange(e) {
        if (e.target.checked) {
            setIsChecked(true)
            setAllChecked([...allchecked, e.target.value]);
        } else {
            setIsChecked(false)
            setAllChecked(allchecked.filter((item) => item !== e.target.value));
        }
    }

    function handleDiscount(e) {

        // const numberRegex = /^[0-9]/;
        // const value = e.target.value;
        // if (!value.match(numberRegex)) {
        //     return ShowErrorToast('Please input number only!');
        // } else {
        // }

        setDiscountValue(e.target.value);
    }

    const discountChecked = (e) => {
        setDiscount(e.target.checked)
    }

    const handleSelect = (data) => {
        if((Array.isArray(availableUserName) && availableUserName.length === 0)) {
            return ShowErrorToast("Please create a user!");
        } else {
            setSelectedName(data);
        }
    }

    const statusUpdate = (data) => {
        setStatus(data)
    }

    return (
        <>

            <div className="container-fluid tables" id="jewellarypage">
                <table id="ornaments" className="table-responsive-xl">
                    <thead>
                        <tr>
                            <td className="table-header" colSpan={6}><h6>Ornaments Detail</h6></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='addbtn-inInvoice'>
                                <label>Customer Name</label>
                                <ModalPopup getStatusUpdate={statusUpdate} />
                            </td>
                            <td>
                                <div id="data-type" className="dropdown-container">
                                    <Select
                                        onChange={handleSelect}
                                        options={availableUserName}
                                        placeholder="Select..."
                                        value={selectedName}
                                    />
                                </div>
                            </td>
                            <td><label>Order Date</label></td>
                            <td>
                                <input type="date" id="orderDate" name="orderDate" value={Ornaments.orderDate} onChange={handleInput} />
                            </td>
                            <td>
                                <label>Delivery Date</label>
                            </td>
                            <td>
                                <input type="date" id="deliveryDate" name="deliveryDate" value={Ornaments.deliveryDate} onChange={handleInput} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Notes:</label>
                            </td>
                            <td colSpan={3}>
                                <input
                                    type="text"
                                    name="notes"
                                    className="form-control"
                                    value={Ornaments.notes}
                                    onChange={handleInput}
                                />
                            </td>
                            <td>
                                <label>Invoice No:</label>
                            </td>
                            <td>
                                {invoiceId && itemId ? <input className='invoice-no' type="text" id="invoiceno" name="invoiceNo" value={invoiceNo} disabled={true} /> : <>
                                    <input className='invoice-no' type="text" id="invoiceNo" name="invoiceNo" value={invoiceNo} disabled />
                                </>}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br></br>
                <table id='Details' className='table table-striped'>
                    <tbody colSpan={15}>
                        <tr>
                            <th className="table-header" colSpan={14}><h6>Jewellery Details</h6></th>
                        </tr>
                        <tr>
                            <td>Item Name</td>
                            <td>Bullion</td>
                            <td>Rate</td>
                            <td>GR.WT</td>
                            <td>NT.WT</td>
                            <td>Item Cost</td>
                            <td>Decscription</td>
                            <td>Stone WT</td>
                            <td>Pcs.</td>
                            <td>Stone Rate</td>
                            <td>Stone Cost</td>
                            <td>Labour</td>
                            <td>Total Cost</td>
                            <td>
                                <button className='plusbtn' onClick={addRowTable}>
                                    <img className="image-size" src={plus} alt='Plus button' />
                                </button>
                            </td>
                        </tr>
                        {
                            rows.map((rowsData, index) => {
                                let { itemName, itemPrice, bullion, labour, groceWeight, netWeight, itemCost, description, stoneWeight, stonePcs, stoneRate, stoneCost, totalCost } = rowsData;


                                if (bullion === "Gold(10gm)") {
                                    itemCost = itemPrice * Number(netWeight) / 10;
                                }
                                if (bullion === "Silver(1kg)") {
                                    itemCost = itemPrice * Number(netWeight) / 1000;
                                }
                                if (bullion === "NPM" || bullion === "Silver925") {
                                    itemCost = itemPrice;
                                }
                                if (stoneWeight) {
                                    const rate = Number(stoneWeight) * stoneRate;
                                    stoneCost = isNaN(rate) ? 0 : rate;
                                }
                                if (stoneCost || itemCost) {
                                    totalCost = Number(itemCost ? itemCost : 0) + Number(stoneCost ? stoneCost : 0);
                                }

                                if (labour !== "") {
                                    totalCost = Number(totalCost) + Number(labour);
                                }
                                amount = amount + totalCost;

                                itemCostPerItem = itemCost;
                                totalItemCostPerItem = totalCost;
                                stoneCostPerItem = stoneCost;

                                rowsData.itemCost = itemCostPerItem;
                                rowsData.stoneCost = stoneCostPerItem;
                                rowsData.totalCost = totalItemCostPerItem;

                                itemCostPerItem = 0;
                                totalItemCostPerItem = 0;
                                stoneCostPerItem = 0;

                                return (
                                    <tr key={index}>
                                        <td>
                                            <input
                                                type="text"
                                                value={itemName}
                                                onChange={(event) => onValUpdate(index, event)}
                                                name="itemName"
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <select className="select-box" name="bullion" value={bullion} onChange={(event) => onValUpdate(index, event)}>
                                                <option value=""></option>
                                                <option value="Silver(1kg)">Silver(1kg)</option>
                                                <option value="Gold(10gm)">Gold(10gm)</option>
                                                <option value="NPM">NPM</option>
                                                <option value="Silver925">Silver 925</option>
                                            </select>
                                        </td>
                                        <td style={{ "paddingBttom": "13px" }}>
                                            <input
                                                type="number"
                                                value={itemPrice}
                                                onChange={(event) => onValUpdate(index, event)}
                                                name="itemPrice"
                                                placeholder='0.00'
                                                className="form-control"
                                            />
                                        </td>

                                        {
                                            (bullion === "NPM" || bullion === "Silver925") ? <>
                                                <td key={index}>
                                                    <input
                                                        type="text"
                                                        value={groceWeight}
                                                        onChange={(event) => onValUpdate(index, event)}
                                                        name="groceWeight"
                                                        placeholder='0.00'
                                                        disabled
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td key={index}>
                                                    <input
                                                        type="text"
                                                        value={netWeight}
                                                        onChange={(event) => onValUpdate(index, event)}
                                                        name="netWeight"
                                                        placeholder='0.00'
                                                        disabled
                                                        className="form-control"
                                                    />
                                                </td>
                                            </> : <>
                                                <td>
                                                    <input
                                                        type="number"
                                                        value={groceWeight}
                                                        onChange={(event) => onValUpdate(index, event)}
                                                        name="groceWeight"
                                                        placeholder='0.00'
                                                        className="form-control"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        value={netWeight}
                                                        onChange={(event) => onValUpdate(index, event)}
                                                        name="netWeight"
                                                        placeholder='0.00'
                                                        className="form-control"
                                                    />
                                                </td>
                                            </>
                                        }
                                        <td>
                                            <input
                                                type="number"
                                                value={itemCost}
                                                onChange={(event) => onValUpdate(index, event)}
                                                name="itemCost"
                                                placeholder='0.00'
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={description}
                                                onChange={(event) => onValUpdate(index, event)}
                                                name="description"
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={stoneWeight}
                                                onChange={(event) => onValUpdate(index, event)}
                                                name="stoneWeight"
                                                placeholder='0.00'
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={stonePcs}
                                                onChange={(event) => onValUpdate(index, event)}
                                                name="stonePcs"
                                                placeholder='0.00'
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={stoneRate}
                                                onChange={(event) => onValUpdate(index, event)}
                                                name="stoneRate"
                                                placeholder='0.00'
                                                className="form-control"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                value={stoneCost}
                                                onChange={(event) => onValUpdate(index, event)}
                                                name="stoneCost"
                                                placeholder='0.00'
                                                className="form-control"
                                            />
                                        </td>
                                        {(bullion === "NPM" || bullion === "Silver925") ? <>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={labour}
                                                    onChange={(event) => onValUpdate(index, event)}
                                                    name="labour"
                                                    placeholder='0.00'
                                                    disabled
                                                    className="form-control"
                                                />
                                            </td>
                                        </> : <>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={labour}
                                                    onChange={(event) => onValUpdate(index, event)}
                                                    name="labour"
                                                    placeholder='0.00'
                                                    className="form-control"
                                                />
                                            </td>
                                        </>}
                                        <td>
                                            <input
                                                type="number"
                                                value={totalCost}
                                                onChange={(event) => onValUpdate(index, event)}
                                                name="totalCost"
                                                placeholder='0.00'
                                                className="form-control"
                                            />
                                        </td>

                                        {rows.length < 2 ? <td></td> : <></>}

                                        {rows.length > 1 ? <td>
                                            <button>
                                                <img className="image-size" src={Delete} alt='Delete Icon' onClick={() => tableRowRemove(index)} />
                                            </button>
                                        </td> : <></>}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tbody colSpan={14}>
                        <tr className='last'>
                            <td>Receipt Mode</td>
                            <td>Cash <input type='checkbox' name="payment" value="Cash" checked={selectedOption === "Cash"} onChange={onValueChange} /></td>
                            <td>UPI <input type='checkbox' name="payment" value="UPI" checked={selectedOption === "UPI"} onChange={onValueChange} /></td>
                            <td>Cheque <input type='checkbox' name="payment" value="Cheque" checked={selectedOption === "Cheque"} onChange={onValueChange} /></td>
                            <td>Card <input type='checkbox' name="payment" value="Card" checked={selectedOption === "Card"} onChange={onValueChange} /></td>
                            {isChecked ? <>
                                <td>CGST(1.5%)<input type='checkbox' value="1.5%" onChange={handleChange} checked={true} /></td>
                                <td>SGST(1.5%) <input type='checkbox' value="1.5%" onChange={handleChange} checked={true} /></td>
                            </> : <>
                                <td>CGST(1.5%)<input type='checkbox' value="1.5%" onChange={handleChange} /></td>
                                <td>SGST(1.5%) <input type='checkbox' value="1.5%" onChange={handleChange} /></td>
                            </>}

                            {discount ? <td colSpan={1}>Discount <input type='checkbox' onChange={discountChecked} checked={true} /></td> :
                                <td colSpan={1}>Discount <input type='checkbox' onChange={discountChecked} /></td>}

                            <td colSpan={2}>
                                {discount && <input className='lastinput form-control' type='number' name="discountValue" id='discountValue'
                                    value={discountValue} onChange={handleDiscount} />}
                            </td>
                            <td colSpan={2}></td>
                            <td colSpan={1}><input type='number' name="amount" value={amount} className="form-control" /></td>
                            <td colSpan={1}></td>
                        </tr>
                        <tr>
                            <td colSpan={7}>
                                <button className='savebtn saveBtn-left btn btn-success login100-form-btn' onClick={() => { onSave('') }}>Save</button>

                            </td>
                            <td colSpan={7}>
                                <button className='savebtn btn btn-success login100-form-btn' onClick={() => { onSave('print') }}>Save & Print</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='footer-section-division'></div>

            <ToastContainer />
        </>
    )
};

export default Invoice;
