import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import logo from './logo.svg';
import './App.css';
import Login from "./pages/login";
import Sale from "./pages/sale.js";
import Invoice from './pages/invoice';
import ProtectedRoutes from './pages/protectedroute';
import Table from './pages/List';
import SalesInvoice from './component/salsreport/salesInvoice';

function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route path='/invoice' element={<Invoice />} exact />
            <Route path='/invoice/:invoiceId/:itemId' element={<Invoice />} exact />
            <Route path='/home' element={<Sale />} exact />
            <Route path='/report' element={<Table />} />
          </Route>
          <Route path='/saleinvoice/:id' element={<SalesInvoice />}/>
          <Route path='/' exact element={<Login />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
