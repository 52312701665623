import React from 'react'

const ClientDeatils = ({ invoiceNo, oDate, dDate, clientDetails }) => {

    oDate = new Date(oDate).toLocaleDateString('en-US');
    dDate = new Date(dDate).toLocaleDateString('en-US');
    const dob = new Date(clientDetails?.dob).toLocaleDateString('en-US');
    return (
        <>
            <div className='bottom-header'>
                <div className="left-side">
                    <h1>
                        <span><b>Details Of Receiver(Bill To):</b></span>
                    </h1>
                    <ul>
                        <li>
                            <span><b>Name:</b></span> {clientDetails ? clientDetails.name : '-'}
                        </li>
                        <li>
                            <span><b>Address:</b></span> {clientDetails ? clientDetails.address : '-'}, {clientDetails ? clientDetails.city : '-'}
                        </li>
                        <li>
                            <span><b>Phone:</b></span> {clientDetails ? clientDetails.mobileNo : '-'}
                        </li>
                        <li>
                            <span><b>DOB:</b></span> {dob ? dob : '-'}
                        </li>
                    </ul>
                </div>
                <div className="right-side">
                    <ul>
                        <li>
                            <span><b>InvoiceNo:</b></span> {invoiceNo ? invoiceNo : '-'}
                        </li>
                        <li>
                            <span><b>Order Date:</b></span> {oDate ? oDate : '-'}
                        </li>
                        <li>
                            <span><b>Delivery Date:</b></span> {dDate ? dDate : '-'}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ClientDeatils;
