import React from "react";

const Footer = () => {

    return (
        <>
            <footer class="footer-section">
                <div class="copyright-area">
                    <div class="container">
                        <div class="row">
                            <div class="text-center">
                                <div class="copyright-text">
                                    <p>Copyright &copy; 2023 Dhyana Jewellers All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    )
}

export default Footer;